import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { mapEdgesToNodes, toPlainText } from "../lib/helpers";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import unicorns from "../images/philosophy/philosophy_3-unicorn.gif";
import hero from "../images/philosophy/philosophy.png";
import bugs from "../images/philosophy/mosquitoes.svg";
import ButtonSolid from "../components/Button/ButtonSolid";

export const data = graphql`
{
    
    philosophyHero: file(relativePath: { eq: "philosophy/hero-basic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }     
    blockImage: file(relativePath: { eq: "homepage/Image.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }     
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }     
    homepageVenDiagram: file(relativePath: { eq: "homepage/2-intro.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    } 
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }    
    homepageHeroCoins: file(relativePath: { eq: "homepage/hero-illustration-grouped.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }    
    homepageHeroDesktop: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    subServices: allSanitySubService(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    sectionDivider: file(relativePath: { eq: "philosophy/section-divider.svg" }) {
      publicURL
    }      
    horseHeadBlue: file(relativePath: { eq: "global/logo-head-blue.svg" }) {
      publicURL
    }  
      philosophyHeroUnderlay: file(relativePath: { eq: "philosophy/2-line background.svg" }) {
      publicURL
    }
    hero: file(relativePath: { eq: "foreign Tax/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 624)
      }
    }
    taxesCanBeStressful: file(
      relativePath: { eq: "Personal Taxes/taxes-can-be-stressful.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    team: file(relativePath: { eq: "Personal Taxes/team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    compOut: file(relativePath: { eq: "foreign Tax/outbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    compIn: file(relativePath: { eq: "foreign Tax/inbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    taxes: file(relativePath: { eq: "Personal Taxes/taxes.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
        orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }    
    homepageDictionary: file(
      relativePath: { eq: "homepage/4-dictionary.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 493)
      }
    }
  }
`;

const Page = ({ data }) => {
  // const faqs = [
  //     {
  //         question:
  //             "U.S. citizens and residents living abroad must still file their U.S. federal taxes, and most expats are required to file additional tax forms before relocating? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     Yeah, I’m going to need help with that. Not to worry—the experts at
  //                     Dark Horse will lead the way.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "Many U.S. taxpayers living abroad actually end up owing no taxes, or a bare minimum?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     How can that be? Oh, it be… because we help you take advantage of
  //                     all the tax benefits and exclusions that are available for expats.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "If an international tax form is not filed or there is a significant error, the penalty can be $10,000 per return (up to $60,000)?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     h no, what if that happens to me? Not to worry—Dark Horse to the
  //                     rescue. We can help with delinquent late filing and abatement, or
  //                     outline a streamlined process for you to get caught up with the
  //                     filings.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question: "The IRS can track your foreign financial accounts? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     They can? Yes, they certainly can. You see, the federal law “FATCA”
  //                     (Foreign Account Tax Compliance Act) requires Americans, U.S.
  //                     residents, and companies to disclose their oversea financial
  //                     holdings, including bank and brokerage accounts, if the threshold is
  //                     met. There is no way to avoid it—FATCA enforces foreign financial
  //                     institutions to report the data of U.S. account owners yearly. We’ll
  //                     help identify and prepare the required filings so you remain
  //                     compliant.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "If you live abroad, you may not have to pay Social Security and Medicare taxes to the U.S.? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     Tell me more. If the country you reside in entered into Totalization
  //                     Agreements with the U.S. (25 countries have), the amount you
  //                     contribute to local social security will give you a credit with the
  //                     U.S. Social Security Administration. If you fit the bill, we’ll let
  //                     you know.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "American expats have different tax filing deadlines and extensions?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     What are those dates? Expats get a special automatic two-month
  //                     extension to June 15. If more time is needed, they can write to the
  //                     IRS to request a further extended deadline (to as far out as
  //                     December 15). Payment of taxes due is still April 15. If you’re bad
  //                     at dates, you can relax. Dark Horse can manage your tax filing and
  //                     make sure you stay on track.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     // {
  //     //   question: "Can I drop my files off at your office?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         The short answer is no. The long answer is that we’re able to keep
  //     //         our prices extremely competitive with a much higher value
  //     //         proposition by working in a cloud-based environment. We’ll ask that
  //     //         you upload your documents to our secure client portal, as this
  //     //         creates indispensable efficiencies in preparing your tax returns, as
  //     //         well as the fact that you will remain the custodian of those
  //     //         documents for peace of mind. We can help you figure out how to
  //     //         digitize those documents, even if you don’t have a scanner. Last,
  //     //         all of your tax documents and tax returns will be saved in the
  //     //         secure portal, allowing for easy access in the future.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question: "Do I need to come into your office to sign documents?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Not at all! Our entire process can be done in the comfort of your
  //     //         home. Any document that we will need you to sign can be
  //     //         electronically signed. All documents (tax documents, tax returns,
  //     //         engagement letters, IRS correspondence, etc.) are saved to your
  //     //         secure portal, accessible at any time.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "I tend to forget what exactly happened on previous years’ tax returns…do you provide a tax return review meeting to go over the current tax return and how it compares to previous years?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Yes, we do! We have a couple of options based on your preference. We
  //     //         can provide you with a review via email, a video recording of your
  //     //         CPA walking through your return, or a 1-to-1 meeting.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "I’m not confident that last year’s tax return was done correctly or that I left deductions on the table. Will you review those tax returns to address these doubts?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Of course. That’s part of our onboarding process with new clients.
  //     //         We’ll let you know if there are any red flags or missed
  //     //         opportunities on those returns. If necessary, we can amend previous
  //     //         tax returns to make sure those issue or opportunities are taken care
  //     //         of.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question: "How much do you charge?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Prices range based on the scope of the engagement. We do offer a
  //     //         unique price quote tool on our website found{" "}
  //     //         <AniLink fade to="/personal-pricing/">
  //     //           here
  //     //         </AniLink>{" "}
  //     //         to give you an idea. Generally, it’s based on your income/deduction
  //     //         sources, how many states you need to file in, and if there are any
  //     //         unique situations with your return. If you are curious, you can
  //     //         always request a quote from us{" "}
  //     //         <AniLink fade to="/get-a-quote/">
  //     //           here
  //     //         </AniLink>
  //     //         .
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "Do you prepare returns in-person like the brick and mortar tax prep firms?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         No, we do not. The reason is that the tax returns we prepare for
  //     //         clients have too much at stake to do all in one sitting. We prepare
  //     //         your returns thoughtfully, review them for quality and accuracy and
  //     //         then evaluate them strategically to make sure you're getting the
  //     //         best result possible. It's impractical (and impossible) to try to do
  //     //         this while you're sitting across from us. Also, you've got better
  //     //         things to do than sit around while we prepare the returns anyway.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  // ];



  const { props, errors } = data;
  const page = props && props.page;


  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 300) : str;
  };

  const subServiceNodes =
    data && data.subServices && mapEdgesToNodes(data.subServices);

  return (
    <Layout className="bg-secondary-100 " serviceType="cpa">
      <SearchEngineOptimization
        title="Our Philosophy | Dark Horse Private Wealth"
        description="Stop chasing unicorns... partner with Dark Horse to grow your wealth. Because a well-crafted investment plan and disciplined approach wins in the long run."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative bg-primary-600 pt-20 pb-20 md:pt-32 md:pb-36">
        <div className="container">
          <div className="mb-6 grid items-center gap-y-14 md:grid-cols-2 md:gap-x-10 lg:gap-x-28">
            <div className="order-last md:order-first">
              <div className="text-white">
                <h2 className="text-white">Philosophically Speaking... </h2>
                <p>
                  We live in a time of unparalleled access to financial markets.
                  We also live in a time of complexity, uncertainty, and opacity
                  that benefit the few at the expense of the many.
                </p>
                <p>
                  The same principles that have created wealth over the past
                  century, however, hold true even in today’s modern context.{" "}
                </p>
                <p>
                  A thoughtfully crafted investment thesis and the discipline to
                  stick to the game plan still wins in the long run. What
                  doesn’t win is emotionally motivated, short-sighted decisions.
                  We know this. You know this.
                </p>
              </div>
            </div>
            <div className="order-first md:order-last">
              <div className="relative ml-8 mr-4 lg:mx-0">
                <img src={hero} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-pattern-triangles bg-[right 1rem] relative h-[75px] w-full overflow-hidden bg-primary-600 bg-repeat-x py-2">
          <div className="w-full" />
        </div>
      </section>

      <section className="bg-pattern-triangles bg-secondary-50  bg-[left_bottom_10rem]  bg-repeat-x pt-10 md:pt-16 lg:pt-26">
        <div className="container">
          <div className="mx-auto mb-1 text-center md:mb-14">
            <h2 className="text-primary-600">
              The Heroes & Villains of this Story
            </h2>

            <p className="mx-auto md:max-w-[370px]">
              <b>Dark Horse clients are our heroes</b>, and we exist to guide
              them to unparalleled success.
            </p>
          </div>
          {/* CARD */}
          <div className=" mb-20 rounded-3xl bg-white px-6 pt-16 pb-20 text-primary-600 shadow-4xl md:mb-32 md:px-14 md:pb-[5px]">
            <div className="relative mx-auto">
              {/* inner grid content */}
              <div className=" relative top-[32px] grid grid-cols-1 gap-y-3 px-6 py-2 text-sm text-[#485159]  md:mb-32 md:grid-cols-1 md:gap-y-[5px] md:gap-x-10 md:px-24 lg:grid-cols-2 lg:gap-x-20">
                {/* left col */}
                <div className="w-full">
                  <p className="has-dropcap dropcaps text-xl leading-loose">
                    In every story, a hero also needs a guide. We believe{" "}
                    <b>your financial advisor should be your guide</b> and your
                    advocate. It should be someone who’s plain-spoken,
                    straight-shooting, and results-oriented.{" "}
                  </p>
                  <div className="md:min-h-[75px]" />
                </div>
                {/* right col */}
                <div className="w-full">
                  <ul className="grid gap-y-3 text-base font-normal md:gap-y-[40px]">
                    <li className="flex items-center gap-x-3 py-[5px]">
                      <i className="fa fa-xl fa-times relative -top-2 rotate-45 text-primary-100" />
                      <span>
                        You shouldn’t wonder if a confusing product or strategy
                        is in your best interest.
                      </span>
                    </li>
                    <li className="flex items-center gap-x-3 py-[5px]">
                      <i className="fa fa-xl fa-times relative -top-2 rotate-45 text-primary-100" />
                      <span>
                        You should know what the services actually cost.
                      </span>
                    </li>
                    <li className="flex items-center gap-x-3 py-[5px]">
                      <i className="fa fa-xl fa-times relative -top-2 rotate-45 text-primary-100" />
                      <span>
                        You shouldn’t worry whether your investments generate
                        unnecessary taxes and drag down your returns.
                      </span>
                    </li>
                    <li className="flex items-center gap-x-3 py-[5px]">
                      <i className="fa fa-xl fa-times relative -top-2 rotate-45 text-primary-100" />
                      <span>
                        Your mind should be at ease knowing you’ve got the right
                        team behind you, deploying strategies to maximize your
                        wealth and allow you to live the life you want before
                        you retire.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* END OF CARD */}
        </div>
        <div className="w-full ">
          <div className="relative">
            <div className="z-[5]">
              <p className="mx-auto mb-0 pb-0 text-center md:max-w-[672px] text-[20px] leading-[30px]">
                Every story also needs a villain. The investment world has many
                villains. <b>The villains are opaque fees and avoidable taxes</b>, sucking the
                blood out of your returns year in and year out. And like a
                mosquito in the night, you don’t know they’ve sucked your blood
                until you wake up to what’s happening.
              </p>
            </div>
            <div className="grid w-full grid-cols-12">
              <img className="col-start-10 col-span-2 md:-ml-[6px0] md:-mt-[201px]" src={bugs} />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary-100  pb-20 ">
        <div className="z-[1]  h-[10rem] w-full overflow-hidden bg-[url('../images/philosophy/section-divider.svg')] bg-[length:100%] bg-[center_bottom_0rem] bg-no-repeat" />
        <div className="container  pt-10  md:pt-16  ">
          <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-16 lg:gap-x-28">
            <div className="order-2">
              <h3>Stop Chasing Unicorns</h3>
              <p>
                A good financial advisor has been statistically shown to provide
                an additional 3% return on your capital each year, according to
                a <a href="https://advisors.vanguard.com/insights/article/IWE_ResPuttingAValueOnValue" target="_blank">study by Vanguard</a>. At the same time,
                studies also show that investment managers are very unlikely to
                outperform low-cost index funds with their higher risk, higher
                fee products over the long term.
              </p>
              <p>
                What these two realities illustrate is that there are very few
                unicorns out there that will give you a long-term advantage over
                the rest of the market. So, instead of chasing unicorns, you’d
                be much better served to ride with the market, dollar-cost
                average your investments, and minimize your fees and your taxes.
              </p>
              <p>
                That last part is where most firms fall short. Most firms don’t
                have a business model that enables them to charge lower, more
                transparent fees nor the expertise of in-house CPAs that help
                lower the tax drag on your portfolio.
              </p>
              <p>
                The collaboration you think might be happening between your
                wealth management firm and your CPA firm probably isn’t. You'll
                likely get a blank stare or an otherwise inadequate response if
                you ask either side to articulate how they’re working together.
              </p>
              <div className="md:hidden">
              <ButtonSolid href="/schedule-consult/" text="Book a Consult" />
              </div>
            </div>
            <div className="order-1">
              <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                <div className="relative z-10">
                  <img src={unicorns} className="z-0 w-full rounded-4xl" />
                  <div className=" absolute -left-1.5 top-1.5 z-[-10] h-full w-full rounded-3xl bg-primary-500 md:-left-3 md:top-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary-100 pt-10  pb-20  md:pt-16 md:pb-26 lg:pt-26 lg:pb-32">
        <div className="container">
          <div className="relative mt-5 mb-20 ml-2 mr-4 md:mt-3 md:mb-32">
            <div className="relative z-[3] rounded-3xl bg-[#1E2A33] px-4 md:px-8 pt-28 pb-26 md:py-24 ">
              <div className="relative z-[4] mx-auto grid grid-cols-12 md:px-16">
                <div className="col-span-full w-full  md:pr-12 md:col-span-9">
                  <h3 className="heading-three mb-6 text-white">
                    Say Goodbye to Hidden Fees & High Taxes
                  </h3>
                  <div>
                    <p className="text-white">
                    We believe in transparency and alignment with our clients. Because our fees are competitive and straightforward, there is no reason to obscure them. We want you to be able to correlate those fees to the value we help you create through growing and protecting your wealth and trimming your tax bill.
                    </p>
                    <p className="text-white">
                      We’d appreciate the opportunity to demonstrate this in
                      action by performing a complimentary comparative analysis
                      that will give you transparency into the fees and taxes
                      you’re paying versus the fees and taxes you would have
                      paid as a Dark Horse Private Wealth client.
                    </p>
                  </div>
                </div>
                <div className="col-span-full grid w-full place-items-center md:col-span-3 md:grid-cols-2">
                  <div className="hidden w-auto md:block"></div>
                  <ButtonSolid href="/schedule-consult/" text="Talk to a Wealth Strategist" className="bg-[#E1C05F] hover:bg-[#f3e6bf] hover:text-black"/>
                </div>
              </div>
            </div>
            <div className="goldenGrad absolute left-2 top-2 z-[1] h-full w-full rounded-3xl md:left-3 md:top-3"></div>
          </div>
        </div>
      </section>
    </Layout>
  );
};



export default Page;
